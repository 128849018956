<script setup lang="ts">
import { Cookie } from '@/types/enums'
import { MetaTags } from '~/types/constants'

const locationParam = useCookie(Cookie.LocationParam)

useServerSeoMeta({
  title: MetaTags.index.title,
  description: MetaTags.index.description,

  ogTitle: MetaTags.index.title,
  ogImage: MetaTags.index.image,
  ogDescription: MetaTags.index.description,
  ogUrl: MetaTags.index.canonical,
  ogType: MetaTags.index.type,

  twitterTitle: MetaTags.index.title,
  twitterDescription: MetaTags.index.description,
  twitterImage: MetaTags.index.image,
  twitterCard: MetaTags.index.twitterCard,

  robots: MetaTags.index.robots,
  articleModifiedTime: MetaTags.index.articleModifiedTime,
})

useHead({
  htmlAttrs: {
    lang: 'pt-BR',
  },
  link: [
    {
      rel: 'canonical',
      href: `${MetaTags.index.canonical}/${locationParam.value}`,
    },
  ],
})

onNuxtReady(() => {
  if (useErrorMessage().value) {
    toast({
      severity: 'warn',
      summary: 'Aviso',
      detail: useErrorMessage().value,
    })
  }
})
</script>

<template>
  <div>
    <WHeroBanner />

    <WBannerOCR class="hidden" />

    <LazyWFeatureCard :class="isTrevo() ? 'mt-0' : 'mt-14 lg:mt-24'" />

    <LazyWPartners v-if="isTrevo()" />

    <LazyWFlipCard v-if="isTrevo()" />

    <LazyWBlogList v-if="isTrevo()" />

    <LazyWClientReview v-if="isTrevo()" />

    <LazyWTrevoMessage v-if="isTrevo()" />
  </div>
</template>
