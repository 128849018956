<script setup lang="ts">
const image = computed(() => isTrevo() ? 'https://web-cdn.saudetrevo.com.br/images/hero-full.png' : 'https://web-cdn.saudetrevo.com.br/images/hero-full-lab.png')
</script>

<template>
  <HeroBanner
    with-search
    :image="image"
    width="476"
    height="478"
  >
    <template #title>
      Mais economia para
      <br class="hidden xl:inline" />
      seus exames!
    </template>
    <template #subtitle>
      Busque todos seus exames e encontramos os
      <br class="hidden xl:inline" />
      melhores preços para você!
    </template>
  </HeroBanner>
</template>
