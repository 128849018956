<script setup lang="ts">
import type { IOCRResponse, IProcedureResponseProcedure } from '@/types/types'
import { Cookie, EventName, LocalStorageKey } from '@/types/enums'

defineProps({
  compact: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['startProcedureOCR', 'finishedProcedureOCR', 'canceledProcedureOCR'])

const locationParam = useCookie(Cookie.LocationParam)

const { showOcrModal: showProcedureOCR } = useOcr()
const loading = ref(false)
const fileUploadError = ref(false)
const withOCR = ref(false)
const fileInput = ref<File | null>(null)
const ocrResult = ref<IOCRResponse | null>(null)
const prescriptionHashes = useLocalStorage<string[]>(LocalStorageKey.PRESCRIPTION_HASHES, [])

const { addSlug, removeSlug, procedures, submitOCR, getPrescriptionIds } = useProcedures()
const { customer } = useCustomer()

const initialSelected = computed(() => {
  if (!procedures.value?.length || procedures.value.length === 1)
    return null as never

  return procedures.value.map(({ procedure: { name, slug, id } }: any) => ({ name, slug, id }))
})

async function startProcedureOCR(): Promise<void> {
  emit('startProcedureOCR')
  loading.value = true

  if (fileInput.value) {
    fileUploadError.value = false
    try {
      ocrResult.value = await submitOCR(fileInput.value)

      if (customer.value?.id && ocrResult.value?.fileHash) {
        await getPrescriptionIds(customer.value.id, [ocrResult.value.fileHash])
      }
      else if (ocrResult.value?.fileHash) {
        prescriptionHashes.value.push(ocrResult.value.fileHash)
      }

      withOCR.value = true
    }
    catch {
      fileUploadError.value = true
    }
    finally {
      loading.value = false
    }
  }
}

function finishProcedureOCR(): void {
  let path = `/${locationParam.value}/exames?exames=${useProcedureSlug().value?.join(',')}`

  emit('finishedProcedureOCR')

  if (!customer.value?.id && withOCR.value) {
    path = `${path}&authRequired=true`
  }

  navigateTo(path)
  closeProcedureOCR()
}

function closeProcedureOCR(): void {
  emit('canceledProcedureOCR')
  showProcedureOCR.value = false
  fileInput.value = null
}

function handleFileInput(file: any): void {
  fileInput.value = file
}

function procedureNameShow(name: string): string {
  return name.length > 45 ? `${name.slice(0, 45)}...` : name
}

function openSideSheet(): void {
  showProcedureOCR.value = true
}

function handleRemoveSlug(procedure: IProcedureResponseProcedure) {
  track(EventName.ExamRemove, {
    procedureDisplayId: procedure.id,
    displayName: procedure.name,
  })

  removeSlug(procedure.slug)
}

watch(fileInput, (newFile) => {
  if (newFile) {
    startProcedureOCR()
  }
})
</script>

<template>
  <div
    id="banner-ocr"
    :class="`container mx-auto mb-14 px-5 lg:px-0 flex relative z-1 -mt-[8rem] md:mt-0 z-10
      ${compact ? 'compact' : 'homepage'}`"
  >
    <Img
      src="https://web-cdn.saudetrevo.com.br/images/leia_seu_pedido_medico.png"
      alt="ocr"
      width="100%"
      height="140"
      classes="hidden lg:block rounded-2xl w-full max-w-[264px] h-[140px] object-cover"
      lazy-load
      loading="lazy"
    />
    <div
      class="w-full min-h-[140px] bg-secondary-100 rounded-2xl py-6 px-8
      flex flex-col md:flex-row justify-between items-center gap-8"
    >
      <div>
        <h3 class="text-secondary-500 text-xl font-sora font-semibold mb-2">
          Agora você pode fazer seu orçamento rapidamente usando seu pedido médico.
        </h3>
        <p v-if="!compact" class="text-secondary-500 text-sm">
          Experimente e veja como é simples e rápido obter seu orçamento!
        </p>
      </div>
      <Button class="whitespace-nowrap" @click="openSideSheet">
        Enviar Pedido Médico
      </Button>
    </div>

    <SideSheet :show="showProcedureOCR" no-title @close-modal="closeProcedureOCR">
      <template #content>
        <div class="px-4 h-full w-full">
          <transition name="fade" mode="out-in">
            <div v-if="loading" class="modal-content justify-center items-center">
              <IllustrationSearchingState />
            </div>
            <div v-else class="modal-content">
              <div class="content-header">
                <h2 class="text-2xl font-bold mb-4">
                  Confirme os exames encontrados
                </h2>
                <p class="text-lg mb-4">
                  Adicione ou remova exames caso a lista encontrada não esteja correta.
                </p>
                <Icon
                  name="mdi:close"
                  class="text-neutral-400 absolute right-0 top-0 p-2 w-10 h-10 cursor-pointer"
                  @click="closeProcedureOCR"
                />
              </div>
              <div class="content-body">
                <div v-if="fileUploadError" class="flex flex-col">
                  <Icon
                    name="mdi:magnify"
                    class="bg-primary-50 text-primary-400 w-12 h-12 rounded-full p-2 mx-auto mb-8"
                  />
                  <p class="text-neutral-200 text-center mb-8">
                    Parece que o texto no arquivo pode estar um pouco difícil de ler ou a imagem talvez não tenha uma
                    boa
                    resolução. Você pode tentar enviar um novo arquivo ou, se preferir, realizar a busca manualmente
                    abaixo.
                  </p>
                </div>
                <UploadFile type="file" class="mb-4" @change="handleFileInput" />
                <SearchProcedure
                  id="ocr-input"
                  no-button
                  no-badge
                  :initial-selected="initialSelected"
                  without-ordenation
                  @remove-procedure="handleRemoveSlug"
                  @select-procedure="addSlug"
                />
                <div v-if="procedures.length" data-testid="procedure-selected-list">
                  <span class="block text-base font-bold my-2" data-testid="procedure-selected-list-label">{{ procedures.length }} exames encontrados</span>
                  <ul class="selected-list">
                    <li
                      v-for="({ procedure }, index) in procedures"
                      :key="index"
                      data-testid="procedure-selected-list-item"
                      class="relative py-2"
                    >
                      {{ procedureNameShow(procedure.name) }}
                      <Icon
                        name="mdi:close"
                        class="text-neutral-400 absolute right-0 top-2 p-0 w-6 h-6 cursor-pointer"
                        @click="handleRemoveSlug(procedure)"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div v-if="procedures.length" class="content-footer">
                <Button
                  data-testid="search-procedure-budget-button"
                  class="w-full h-[56px] text-white font-bold"
                  @click="finishProcedureOCR"
                >
                  Buscar orçamento
                </Button>
                <Button class="w-full h-[56px] text-white font-bold" variant="outlined" @click="closeProcedureOCR">
                  Cancelar
                </Button>
              </div>
            </div>
          </transition>
        </div>
      </template>
    </SideSheet>
  </div>
</template>

<style lang="postcss" scoped>
.modal-content{
  @apply h-full w-full flex flex-col;
}

.content-header {
  @apply relative;
}

.content-body {
  @apply h-full flex flex-col gap-4 justify-center overflow-y-auto;
}

.content-footer {
  @apply w-full flex gap-2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
